import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import languagesList from '../../../api/languages';
import { PREFERRED_LANG } from '../../../constants/appConstants';

const useStyles = makeStyles((theme) => ({
  languageButton: {
    marginRight: '10px',
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.outlineBorder}`,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.outlineBorder}`,
    },
  },
  popover: {
    minWidth: 101,
  },
  menuList: {
    backgroundColor: theme.palette.selectedOptionBgColor,
  },
}));

const SelectLanguage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data: { data: languages = [] } = [] } = useQuery(['LanguageListing', 1], () => languagesList(1), { suspense: false });
  const [preferredLanguage] = useState(JSON.parse(localStorage.getItem(PREFERRED_LANG))?.name);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetIndex = (languageData) => {
    localStorage.setItem(PREFERRED_LANG, JSON.stringify(languageData));
    handleClose();
    navigate(0);
  };

  return languages && (
    <>
      <Button classes={{ root: classes.languageButton }} variant="outlined" fontSize="small" endIcon={<Icon>arrow_drop_down</Icon>} onClick={handleClick}>
        {preferredLanguage}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{
          paper: classes.popover,
        }}
      >
        {languages.map((option) => (
          <MenuItem
            classes={{ root: classes.menuItem }}
            selected={preferredLanguage === option.name}
            key={option.id}
            onClick={() => handleSetIndex(option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SelectLanguage;
