import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3.25),
  },
  menuItem: {
    fontSize: theme.spacing(1.75),
    fontWeight: 'normal',
    backgroundColor: '#353535',
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  menuListContainer: {
    '& .MuiMenuItem-divider': {
      borderTop: '1px solid rgba(255, 255, 255, 0.31)',
      borderBottom: 'none',
    },
  },
  topBarHeader: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#353535',
    },
  },
}));

export default useStyles;
