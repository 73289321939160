import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  infoArea: {
    marginBottom: '15px',
  },
  container: {
    padding: '0px 8px 0px 8px',
  },
  dataItemLabel: {
    opacity: '0.6',
  },
  datePicker: {
    '& input': {
      paddingBottom: '5px',
      paddingTop: '10px',
    },
    '& button': {
      padding: '0px',
    },
  },
  subscriptionDate: {
    display: 'inline-grid',
  },
  infoLabel: {
    marginBlock: '10px',
  },
  warningText: {
    whiteSpace: 'nowrap',
    color: theme.palette.error.main,
  },
  textWrap: {
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
