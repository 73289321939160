import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { makeStyles } from '@mui/styles';
import { Trans, t } from '@lingui/macro';
import { useAuth0 } from '@auth0/auth0-react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import {
  Grid,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import useAppContext from '../../../hooks/useAppContext';
import expressLogo from '../../../assets/images/IntertekExpressLogo.svg';
import darkThemeLogo from '../../../assets/images/IntertekLogo.svg';
import lightThemeLogo from '../../../assets/images/alchemy-logo.png';
import Can from '../../../components/Can';
import SelectClients from './SelectClient';
import SelectLanguage from './SelectLanguage';
import ClientMenu from '../../../components/ClientMenu';
import ViewAsUserView from '../../../components/ViewAsUserView';
import ChangePasswordDialog from '../../../containers/ChangePasswordDialog';
import { AS_USER_ID } from '../../../constants/appConstants';

const { REACT_APP_ADMIN_URL, REACT_APP_WP_URL } = process.env;
const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  devText: {
    margin: theme.spacing(1.25),
  },
  clientButton: {
    marginRight: theme.spacing(1.25),
  },
  divider: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(1.75),
  },
  buttonRoot: {
    marginLeft: theme.spacing(1.875),
  },
  buttonProfile: {
    marginLeft: theme.spacing(-0.5),
  },
  profileInitials: {
    marginRight: theme.spacing(0.5),
  },
  ProfileColor: {
    color: 'black',
    backgroundColor: 'white',
    paddingTop: theme.spacing(0.50),
    fontSize: '16px',
    height: '36px',
    width: '36px',
  },
}));

const Topbar = ({ hideTopBarClient }) => {
  const classes = useStyles();
  const { currentEnv, theme } = useAppContext();
  const [isChangePassDialog, setOpen] = useState(false);
  const {
    isAuthenticated,
    user,
  } = useAuth0();
  const isLearnerModeEnabled = useFeatureToggle('enableLearnerMode');
  const viewAsUserID = localStorage.getItem(AS_USER_ID);

  const profileInitialsName = () => {
    const initials = user?.given_name?.charAt(0) + user?.family_name?.charAt(0);
    return initials && initials.toLocaleUpperCase();
  };

  const myPortalMenu = isLearnerModeEnabled ? [
    {
      menuItem: t`My Learning`, permissions: [], url: '/myLearning', viewAsCallerUser: true,
    },
    {
      menuItem: t`My Orders`, permissions: [], url: '/myOrders', viewAsCallerUser: true,
    },
    {
      menuItem: t`My Certificates`, permissions: [], url: '/myCertificates', viewAsCallerUser: true,
    },
    {
      menuItem: t`Manage Enrollments`, permissions: [], url: '/enrollments', viewAsCallerUser: true,
    },
    {
      menuItem: t`Users`, permissions: ['read:zosi_users'], url: '/users', viewAsCallerUser: !viewAsUserID, divider: true,
    },
    {
      menuItem: t`Orders`, permissions: ['read:zosi_orders'], url: '/orders', viewAsCallerUser: !viewAsUserID,
    },
    {
      menuItem: t`Organize Events`, permissions: ['read:organized_events'], url: '/organizeEvents', viewAsCallerUser: !viewAsUserID, divider: true,
    },
  ] : [
    { menuItem: t`Alchemy Express`, permissions: ['read:client_libraries'], url: '/downloads' },
  ];

  return (
    <AppBar
      color="default"
      position="fixed"
    >
      <Toolbar>
        {isLearnerModeEnabled
          ? (
            <a href={REACT_APP_WP_URL}>
              <img
                alt="Logo"
                src={theme ? darkThemeLogo : lightThemeLogo}
                height="32"
                width="85"
              />
            </a>
          )
          : (
            <img
              alt="Logo"
              src={expressLogo}
              height="32"
              width="85"
            />
          )}
        {currentEnv === 'DEV' && (<Grid classes={{ root: classes.devText }}><Trans>DEV ENV</Trans></Grid>)}
        <ClientMenu
          title={t`My Portal`}
          clientRoleDetails={myPortalMenu}
        >
          <ExpandMoreIcon />
        </ClientMenu>
        <div className={classes.flexGrow} />
        {viewAsUserID ? <ViewAsUserView /> : (
          <>
            <Can
              permissions={['search:from_topbar']}
              yes={() => (
                <IconButton aria-label="search item" onClick={() => { }} size="large">
                  <SearchIcon />
                </IconButton>
              )}
            />
            <Divider orientation="vertical" flexItem classes={{ flexItem: classes.divider }} />
            <ClientMenu
              clientRoleDetails={[
                { menuItem: t`Terms of Use`, permissions: [], href: `${REACT_APP_WP_URL}terms-of-use/` },
                { menuItem: t`FAQ`, permissions: [], href: `${REACT_APP_WP_URL}support/` },
                { menuItem: t`Privacy Policy`, permissions: [], href: `${REACT_APP_WP_URL}privacy-policy/` },
                { menuItem: t`Support Center`, permissions: [], href: `${REACT_APP_WP_URL}support/` }]}
            >
              <HelpIcon />
            </ClientMenu>
            <Can
              permissions={['search:from_topbar']}
              yes={() => (
                <IconButton aria-label="search item" onClick={() => { }} size="large">
                  <ShoppingCartIcon />
                </IconButton>
              )}
            />
            <ClientMenu
              className={classes.profileInitials}
              clientRoleDetails={[
                {
                  menuItem: t`Profile`, permissions: [], url: '/profile', id: 'profile',
                },

                {
                  menuItem: t`Change Password`, permissions: [], setChangePassDialog: () => setOpen(true), id: 'password',
                },
                ...(!isLearnerModeEnabled ? [{
                  menuItem: t`Go to Zosi CMS`, permissions: ['goto:zosi_cms_portal'], href: `${REACT_APP_ADMIN_URL}`, id: 'go_to_zosi_cms',
                }] : []),
                { menuItem: isAuthenticated ? t`Logout` : t`Login`, permissions: [], id: isAuthenticated ? t`logout` : t`login` },
              ]}
            >
              {user?.given_name ? (
                <Avatar className={classes.ProfileColor}>
                  {profileInitialsName()}
                </Avatar>
              ) : (<PersonIcon />)}
            </ClientMenu>
            {!hideTopBarClient && !isLearnerModeEnabled && (<SelectClients />)}
            <SelectLanguage />
          </>
        )}
      </Toolbar>
      {
        isChangePassDialog && (
          <ChangePasswordDialog open={isChangePassDialog} setOpen={setOpen} />
        )
      }
    </AppBar>
  );
};
Topbar.defaultProps = {
  hideTopBarClient: true,
};

Topbar.propTypes = {
  hideTopBarClient: PropTypes.bool,
};

export default Topbar;
