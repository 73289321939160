import MuiButton from './MuiButton';
import MuiPopover from './MuiPopover';
import MuiFilledInput from './MuiFilledInput';
import MuiPaper from './MuiPaper';
import MuiMenuItem from './MuiMenuItem';
import MuiLink from './MuiLink';
import MuiDialog from './MuiDialog';
import MuiAppBar from './MuiAppBar';
import Tabs from './Tabs';

export default {
  MuiButton,
  MuiPopover,
  MuiFilledInput,
  MuiPaper,
  MuiMenuItem,
  MuiLink,
  MuiDialog,
  MuiAppBar,
  ...Tabs,
};
