import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: 64,
    },
  },
  content: {
    height: '100%',
  },
  devText: {
    margin: '10px',
    position: 'absolute',
    bottom: 0,
    opacity: '0.6',
  },
}));
export default useStyles;
