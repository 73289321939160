import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import Grid from '@mui/material/Grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import { TextField } from '@mui/material';
import ContentCategoryViewer from '../ContentCategoryViewer/ContentCategoryViewer';
import useStyles from './styles';
import { DATE_SHORT_FORMAT } from '../../constants/appConstants';
import { getExpiryDate } from '../../helper/dateUtilsHelper';

function UserProductInformation({
  product,
  isDateEditMode,
  isSaveDisabled,
  setExpireDate,
  setIsSaveDisabled,
  isComingFromSeatsDetailsPage,
}) {
  const classes = useStyles();

  const isValidDate = (date) => {
    const isValid = moment(date).isValid();
    if (isValid) {
      const disabled = moment(date).isSame(moment(), 'day') ? true : moment(date).isAfter();
      setIsSaveDisabled(!disabled);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const getDateFormat = (date) => {
    if (date) {
      return moment.utc(date).format(DATE_SHORT_FORMAT);
    }
    return '-';
  };

  const getDateFormatUTC = (date) => {
    if (date) {
      const isExpired = () => moment().isAfter(date);
      const getDateInUTC = moment.utc(date).format(DATE_SHORT_FORMAT);
      return (
        <span
          className={(isExpired(date) ? classes.warningText : classes.textWrap)}
        >
          {getExpiryDate(getDateInUTC)}
        </span>
      );
    }
    return '-';
  };

  const getDatePickerValueFormat = (date) => moment.utc(date)
    .utcOffset(0, false);

  const setSubscriptionDate = () => {
    if (isDateEditMode) {
      return (
        <>
          <Grid item md={4} className={classes.subscriptionDate}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                fullWidth
                id="expiration-date"
                inputVariant="filled"
                size="small"
                format={DATE_SHORT_FORMAT}
                inputFormat="dd-MM-yyyy"
                clearable
                disablePast
                slotProps={{
                  textField: {
                    helperText: isSaveDisabled ? t`Select a future date` : null,
                    error: isSaveDisabled,
                    size: 'small',
                  },
                  day: {
                    sx: {
                      '&.MuiPickersDay-root.Mui-selected': {
                        backgroundColor: '#FFC700',
                      },
                    },
                  },
                }}
                value={getDatePickerValueFormat(product.expirationDate)}
                minDate={new Date()}
                onChange={(date) => {
                  isValidDate(date);
                  setExpireDate(date);
                }}
                InputProps={{ classes: { root: classes.datePicker }, disableUnderline: true }}
                renderInput={(props) => <TextField data-testid="expire-date-id" {...props} size="small" helperText={null} />}
              />
            </LocalizationProvider>

          </Grid>
        </>
      );
    }
    return getDateFormatUTC(product.expirationDate);
  };

  const getInfoData = () => {
    if (product.productType === 'resource') {
      return {
        [t`Order Date: `]: getDateFormat(product.orderDate) || '-',
        [t`Order #: `]: product.orderNumber || '-',
        [t`Resource ID: `]: product.productId || '-',
        [t`Country Regulation: `]: product.regulation || '-',
        [t`Language: `]: product.language || '-',
        [t`Partner: `]: product.partner || '-',
        [t`Content Category:`]: <ContentCategoryViewer
          categories={product?.contentCategory}
        />,
      };
    } if (isComingFromSeatsDetailsPage) {
      return {
        [t`Order Date: `]: getDateFormat(product.orderDate) || '-',
        [t`Order #: `]: product.orderNumber || '-',
        [t`Course ID: `]: product.productId || '-',
        [t`Expiration: `]: setSubscriptionDate(),
        [t`Certificate Template: `]: product.certificateTemplateName || '-',
      };
    } return {
      [t`Order Date: `]: getDateFormat(product.orderDate) || '-',
      [t`Order #: `]: product.orderNumber || '-',
      [t`Course ID: `]: product.productId || '-',
      [t`Date Started: `]: getDateFormat(product.dateStarted) || '-',
      [t`Language: `]: product.language || '-',
      [t`Version: `]: product.version || '-',
      [t`Template: `]: product.certificateTemplateName || '-',
      [t`Expiration: `]: setSubscriptionDate(),
      [t`Certificate Issued: `]: getDateFormat(product.certificateIssueDate) || '-',
    };
  };
  const renderItem = (key, value) => (
    <Grid item md={6} xs={12} key={key}>
      <Typography
        className={classes.dataItemLabel}
        display="inline"
        variant="subtitle1"
      >
        {key}
      </Typography>
      { (key === 'Expiration Date:' && isDateEditMode) ? (
        <>
          &nbsp;{ value }
        </>
      ) : (
        <Typography display="inline" variant="subtitle1">
          &nbsp;{ value}
        </Typography>
      )}
    </Grid>
  );

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.infoLabel}
        classes={{
          root: classes.headerRoot,
        }}
      >
        <Grid item classes={{ root: classes.dataItemLabel }}>
          <Typography variant="body2">
            <Trans>Information</Trans>
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={1} className={classes.infoArea}>
        {Object.entries(getInfoData()).map(([key, value]) => renderItem(key, value))}
      </Grid>
    </>
  );
}

UserProductInformation.defaultProps = {
  isComingFromSeatsDetailsPage: false,
};

UserProductInformation.propTypes = {
  product: PropTypes.shape({
    orderDate: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    certificateTemplateName: PropTypes.string,
    expirationDate: PropTypes.string.isRequired,
    language: PropTypes.string,
    dateStarted: PropTypes.string,
    version: PropTypes.string,
    certificateIssueDate: PropTypes.string,
    productType: PropTypes.string,
    regulation: PropTypes.string,
    partner: PropTypes.string,
    contentCategory: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }).isRequired,
  isDateEditMode: PropTypes.bool.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  setExpireDate: PropTypes.func.isRequired,
  setIsSaveDisabled: PropTypes.func.isRequired,
  isComingFromSeatsDetailsPage: PropTypes.bool,
};

export default UserProductInformation;
