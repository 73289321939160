import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    minHeight: '86vh',
  },
  devText: {
    margin: '10px',
    position: 'absolute',
    bottom: 0,
    opacity: '0.6',
  },
}));
export default useStyles;
