import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  userProductDetailsContainer: {
    height: 'calc(93vh - 165px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  container: {
    padding: '0px 8px 0px 8px',
  },
  itemHeader: {
    marginBottom: '20px',
  },
  iconRoot: {
    fontSize: '1rem',
    margin: '5px',
  },
  actionButton: {
    marginRight: '10px',
  },
  actionMenuPopover: {
    minWidth: '101px',
  },
  history: {
    display: 'inline-block',
    borderLeft: '1px solid #fff',
    opacity: '0.6',
  },
  historyIcon: {
    padding: '6px',
  },
}));

export default useStyles;
