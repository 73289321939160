import React from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import useAppContext from '../../hooks/useAppContext';
import Loading from '../Loading';

export default function LoadingIndicator() {
  const { isLoading } = useAppContext();
  return (
    <Loading isLoading={isLoading} withBackDrop />
  );
}
