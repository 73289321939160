import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import getAllClients from '../../../api/admin';
import { checkPermissions, getCompanies } from '../../../helper/userMethods';
import { CLIENT_ID } from '../../../constants/appConstants';

const useStyles = makeStyles((theme) => ({
  clientButton: {
    marginRight: '10px',
    border: `1px solid ${theme.palette.outlineBorder}`,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.selectedOptionBgColor,
      border: `1px solid ${theme.palette.outlineBorder}`,
    },
  },
  popover: {
    minWidth: 200,
  },
}));

const SelectClient = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { data: { data: allCompanies = [] } = [] } = useQuery('GetAllClients', getAllClients);
  const listClients = checkPermissions(user, ['read:selected_companies'])
    ? allCompanies.filter((company) => getCompanies(user).includes(company?.id)) : allCompanies;
  const [selectedIndex] = useState(JSON.parse(localStorage.getItem(CLIENT_ID)));
  const selectedClient = listClients?.find((item) => item.id === Number(selectedIndex.id));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetIndex = (option) => {
    localStorage.setItem(CLIENT_ID, JSON.stringify(option));
    handleClose();
    navigate(0);
  };

  return listClients && (
    <>
      <Button classes={{ root: classes.clientButton }} variant="outlined" fontSize="small" endIcon={<Icon>arrow_drop_down</Icon>} onClick={handleClick}>
        {selectedClient?.name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{
          paper: classes.popover,
        }}
      >
        {listClients.map((option) => (
          <MenuItem
            selected={selectedClient?.id === option.id}
            key={option.id}
            onClick={() => handleSetIndex(option)}
            classes={{ root: classes.selectedClientTitle }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SelectClient;
