import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import Footer from '../commonComponents/Footer/AppFooter';
import useAppContext from '../../hooks/useAppContext';
import { useStyles } from './styles';

const Minimal = (props) => {
  const {
    children,
  } = props;
  const classes = useStyles();
  const { currentEnv } = useAppContext();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {children}
        {currentEnv === 'DEV' && <footer className={classes.devText}><Trans>DEV ENV</Trans></footer>}
      </main>
      <Footer />
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Minimal;
