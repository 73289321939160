import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import { Grid, TextField } from '@mui/material';

const useStyles = makeStyles(() => ({
  actionContainer: {
    padding: '0px 24px 24px',
  },
  notes: {
    width: '510px',
    padding: '10px 25px 22px 25px',
  },
  title: {
    marginTop: '15px',
  },
  dialogText: {
    paddingTop: '0px',
  },
  helperText: {
    margin: '6px 0px 0px 4px !important',
  },
}));

const WarningDialog = ({
  open,
  onClose,
  onSubmit,
  warningMessage,
  postiveButtonText,
  cancelButtonText,
  title,
  showCancelButton,
  isInputVisible,
  setInput,
  inputValue,
  errorMsg,
  inputLabel,
}) => {
  const classes = useStyles();
  const [isError, setError] = useState(false);

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="warning-dialog"
      data-testid="warning-dialog"
      PaperProps={{ variant: 'outlined' }}
    >
      <DialogTitle
        data-testid="alert-dialog-title"
        className={isInputVisible ? classes.title : ''}
      >
        {title}
      </DialogTitle>
      <DialogContent className={isInputVisible ? classes.dialogText : ''}>
        <DialogContentText>{warningMessage}</DialogContentText>
      </DialogContent>
      {isInputVisible && (
        <Grid container xs={12} justifyContent="flex-end">
          <Grid classes={{ root: classes.notes }}>
            <TextField
              fullWidth
              id="notes"
              size="small"
              variant="filled"
              value={inputValue}
              data-testid="dialog-container"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  maxlength: 1000,
                },
              }}
              error={isError}
              helperText={isError ? errorMsg : ''}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
              label={inputLabel}
              multiline
              rows={8}
              name="notes"
              onChange={(e) => {
                if (!e.target.value.trim()) setError(true);
                else setError(false);
                setInput(e.target.value);
              }}
              type="text"
            />
          </Grid>
        </Grid>
      )}
      <DialogActions classes={{ root: classes.actionContainer }}>
        {showCancelButton && (
          <Button onClick={() => onClose()} variant="outlined" color="primary">
            {cancelButtonText}
          </Button>
        )}
        <Button
          onClick={() => onSubmit()}
          disabled={isInputVisible ? inputValue.trim() === '' : false}
          variant="contained"
          color="primary"
        >
          {postiveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  postiveButtonText: PropTypes.string,
  warningMessage: PropTypes.string,
  cancelButtonText: PropTypes.string,
  title: PropTypes.string,
  showCancelButton: PropTypes.bool,
  isInputVisible: PropTypes.bool,
  setInput: PropTypes.func,
  inputValue: PropTypes.string,
  errorMsg: PropTypes.string,
  inputLabel: PropTypes.string,
};

WarningDialog.defaultProps = {
  warningMessage: t`On cancel changes will be lost, are you sure you want to continue?`,
  postiveButtonText: t`YES`,
  cancelButtonText: t`NO`,
  title: '',
  showCancelButton: true,
  inputValue: '',
  errorMsg: '',
  inputLabel: '',
  setInput: null,
  isInputVisible: false,
};

export default WarningDialog;
