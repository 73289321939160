import { t, Trans } from '@lingui/macro';
import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const ContentCategoryViewer = ({ categories }) => {
  const classes = styles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const categoryList = (cat) => {
    const list = cat.map((obj) => obj.name).join(', ');
    return list;
  };
  const list = categoryList(categories);
  const CHARACHTER_LIMIT = 85;
  return (
    <>
      {categories && categories.length > 0 && (
        <>
          {list.length > CHARACHTER_LIMIT ? (
            <>
              <span>
                {list.substring(0, CHARACHTER_LIMIT)}
                {'...'}
              </span>
              <Button
                onClick={() => handleClickOpen()}
                classes={{ label: classes.buttonLabel, root: classes.buttonRoot }}
                color="secondary"
                data-testid="contentCategory"
              >
                <Trans>View All</Trans>
              </Button>
            </>
          ) : (
            list
          )}
        </>
      )}
      {(!categories || categories.length === 0) && <span> - </span>}
      {open
        && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            classes={{
              paper: classes.paperContainer,
            }}
          >
            <DialogTitle
              id="dialog-title"
              classes={{
                root: classes.contentHeader,
              }}
            >
              <span className={classes.dialogTitle}>
                {t`Content Category`}
              </span>
            </DialogTitle>
            <DialogContent>
              <TableContainer classes={{
                root: classes.tableContainer,
              }}
              >
                <Table aria-label="Content category list">
                  <TableBody>
                    <TableRow key="header">
                      <TableCell scope="row" className={clsx(classes.tableCell, classes.columnHeader)}>
                        {t`Category Name`}
                      </TableCell>
                      <TableCell align="left" className={clsx(classes.tableCell, classes.columnHeader)}>{t`Description`}</TableCell>
                    </TableRow>
                    {categories.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell scope="row" className={clsx(classes.tableCell, classes.column1)}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left" className={clsx(classes.tableCell, classes.column2)}>{row.description ? row.description : '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined" data-testid="Close" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
    </>
  );
};

ContentCategoryViewer.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};
ContentCategoryViewer.defaultProps = {
  categories: [],
};

export default ContentCategoryViewer;
