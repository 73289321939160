import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Trans, t } from '@lingui/macro';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { checkPermissions, getCompanies } from '../../helper/userMethods';
import getAllClients from '../../api/admin';
import { CLIENT_ID } from '../../constants/appConstants';
import DropDownSelector from '../../components/DropDownSelector/DropDownSelector';

const useStyles = makeStyles((theme) => ({
  itemHeader: {
    marginBottom: '20px',
  },
  itemContainer: {
    backgroundColor: theme.palette.background.container,
    minHeight: 'calc(100vh - 150px)',
    padding: '20px',
  },
  buttonItem: {
    paddingTop: 8,
    paddingRight: 8,
  },
}));

const ClientSelection = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { data: { data: allCompanies = [] } = [] } = useQuery('GetAllClients', getAllClients);
  const listClients = checkPermissions(user, ['read:selected_companies'])
    ? allCompanies.filter((company) => getCompanies(user).includes(company?.id)) : allCompanies;

  const {
    register, handleSubmit, errors, formState, control,
  } = useForm({ mode: 'all' });

  const { isValid } = formState;
  const submitData = async (data) => {
    const selectedClient = listClients?.filter((item) => item?.name === data.clientSelection)[0];
    localStorage.setItem(CLIENT_ID, JSON.stringify(selectedClient));
    navigate(0);
  };

  return listClients && (
    <Grid container direction="column">
      <Grid item classes={{ root: classes.itemHeader }} xs={12}>
        <Grid
          container
          alignItems="center"
        >
          <Grid item sm="auto" xs={12}>
            <Typography variant="subtitle2">
              <Trans>Company Selection</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item classes={{ root: classes.itemContainer }}>
        <form noValidate onSubmit={handleSubmit(submitData)}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                variant="filled"
                fullWidth
                error={!!errors.clientSelection}
              >
                <Controller
                  id="clientSelection"
                  name="clientSelection"
                  disableUnderline
                  control={control}
                  rules={{ required: t`Please select client` }}
                  render={({
                    name, onChange, value,
                  }) => (
                    <DropDownSelector
                      value={value}
                      optionList={listClients}
                      inputlabel={t`Select Company`}
                      handleChange={onChange}
                      fieldName={name}
                      isError={!!errors.clientSelection}
                      errorMessage={errors.clientSelection?.message}
                      displayLabel={(option) => option.name}
                      inputRef={register({ required: t`Please select client` })}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container classes={{ root: classes.buttonItem }} md={6} xs={12} justifyContent="flex-end">
            <Button variant="contained" disabled={!isValid} color="primary" onClick={handleSubmit(submitData)} fullWidth={matches}><Trans>GO</Trans></Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ClientSelection;
